import React from "react";
import {
  ToggleSwitch,
  PulseAnimation,
  Heading,
  Modal,
  MessageModalLayout,
  Text,
  Card,
  FormField,
  Box,
  Page,
  InputArea,
  Button,
  Cell,
  Layout,
  Input,
  WixDesignSystemProvider,
  Image,
  PageSection,
  Badge,
  MarketingLayout,
  AnnouncementModalLayout,
  InfoIcon,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import "./App.css";
import Nadine from "./Nadine.png";
import { ReactTagManager } from "react-gtm-ts";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const BASE_URL = `https://certifiedcode.editorx.io/code-with-ai/_functions`;
  const [isFirstTime, setIsFirstTime] = React.useState(true);
  const [prompt, setPrompt] = React.useState("");
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [completion, setCompletion] = React.useState("");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false);
  const [isFairUsageModalOpen, setIsFairUsageModalOpen] = React.useState(false);
  const [editInstructions, setEditInstructions] = React.useState("");
  const [usePretrainedModel, setUsePretrainedModel] = React.useState(false);

  // create ref
  const inputAreaRef = React.useRef(null);
  const [isBeta, setIsBeta] = React.useState(true);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");

  React.useEffect(() => {
    // setIntercomIdentity();
    setInstance();
  }, []);

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      });
  }

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=152c3ada-bcd6-4490-b507-039e849f2843&redirectUrl=https://certifiedcode.editorx.io/code-with-ai/_functions/@certifiedcode/base-backend/auth`;
  }

  function getCompletion(type: string) {
    setIsFirstTime(false);
    setIsProcessing(true);
    getCompletionFromApi(type);
  }

  function getCompletionFromApi(type: string) {
    var requestBody = {
      prompt,
      completion,
      instruction: "",
      suffix: "",
      usePreset: false,
    };
    if (type === "edit") {
      requestBody["instruction"] = editInstructions;
    }
    if (type === "insert") {
      // seperate by [insert]
      requestBody["completion"] = completion.split("[insert]")[0];
      requestBody["suffix"] = completion.split("[insert]")[1];
    }
    // requestBody['usePreset'] = usePretrainedModel
    fetch(BASE_URL + "/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: instance || "",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          let code =
            type === "completion"
              ? completion + data.text
              : type === "insert"
              ? completion.replace("[insert]", data.text)
              : data.text;
          setCompletion(code);
          setIsProcessing(false);
          setEditInstructions("");
        } else {
          if (data.canRetry) {
            getCompletionFromApi(type);
          } else {
            if (data.isUpgradeRequired) {
              setIsFairUsageModalOpen(true);
            } else {
              setIsModalOpen(true);
            }
          }
        }
      })
      .catch((error) => {
        setIsModalOpen(true);
      });
  }
  const isPremiumApp = true;

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height="100vh">
        <Page.Header
          title={
            <Box gap={"SP2"} alignItems="middle">
              <Heading>Code with AI</Heading>
            </Box>
          }
          actionsBar={
            <Box gap={"SP2"} direction="horizontal">
              <Button
                skin="premium"
                disable={isUpgraded}
                prefixIcon={<Icons.PremiumFilled />}
                onClick={() => setIsUpgradeModalOpen(true)}
              >
                Get Unlimited Completions
              </Button>
              <Button
                prefixIcon={<Icons.Reviews />}
                skin="inverted"
                as="a"
                href="https://bridget.reviews.certifiedcode.us/152c3ada-bcd6-4490-b507-039e849f2843"
                target="_blank"
              >
                Write a review
              </Button>
              <Button
                prefixIcon={<Icons.RevertReset />}
                disabled={prompt === "" && completion === ""}
                onClick={() => {
                  setPrompt("");
                  setCompletion("");
                  setEditInstructions("");
                  setIsFirstTime(true);
                }}
              >
                Start over
              </Button>
            </Box>
          }
        />
        <Page.Content>
          <Modal isOpen={isFairUsageModalOpen}>
            <MessageModalLayout
              onCloseButtonClick={() => {
                setIsFairUsageModalOpen(false);
              }}
              primaryButtonOnClick={() => {
                setIsFairUsageModalOpen(false);
              }}
              primaryButtonText="Got it"
              secondaryButtonText="Upgrade app"
              secondaryButtonOnClick={() => {
                setIsFairUsageModalOpen(false);
                window.open(
                  `https://www.wix.com/apps/upgrade/${"152c3ada-bcd6-4490-b507-039e849f2843"}?appInstanceId=${
                    (instanceData as any)["instance"]["instanceId"]
                  } `
                );
              }}
              title="We couldn't generate the code"
              content={
                <Text>
                  You've reached the maximum usage in the last 24 hours. Try
                  again later, or upgrade "Code with AI".
                </Text>
              }
              // footnote={
              //   <Text size="small">
              //     Learn more about our fair use policy <a href=''>here</a>.
              //   </Text>
              // }
            />
          </Modal>
          <Modal
            isOpen={isUpgradeModalOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setIsUpgradeModalOpen(false)}
          >
            <AnnouncementModalLayout
              theme="premium"
              title="Start writing code with AI without limit"
              primaryButtonText="Upgrade"
              linkText="Do it later"
              linkOnClick={() => setIsUpgradeModalOpen(false)}
              primaryButtonOnClick={() => {
                window.open(
                  `https://www.wix.com/apps/upgrade/${"152c3ada-bcd6-4490-b507-039e849f2843"}?appInstanceId=${
                    (instanceData as any)["instance"]["instanceId"]
                  } `
                );
              }}
              onCloseButtonClick={() => setIsUpgradeModalOpen(false)}
            >
              <Text>
                Upgrade "Code with AI" to get unlimited completions from the AI
                model trained based on Velo, for you.
              </Text>
            </AnnouncementModalLayout>
          </Modal>
          <Modal isOpen={isModalOpen} screen="desktop">
            <MessageModalLayout
              primaryButtonText="Refresh"
              primaryButtonOnClick={() => window.location.reload()}
              title="Something went wrong"
              content={
                <Text>
                  We are having some issues when generating the code, please
                  refresh this page to continue.
                </Text>
              }
            />
          </Modal>
          <Layout>
            <Cell span={12}>
              <Card>
                <MarketingLayout
                  title="Nadine, our customer success manager, is here to help you"
                  description="Get your project done by Wix Certified Developers."
                  actions={
                    <Button
                      // as="a"
                      // href="https://heyflow.id/code-with-ai_-hire-a-expert"
                      // target="_blank"
                      size="small"
                      id="hire-expert-button"
                    >
                      Send a brief
                    </Button>
                  }
                  size="tiny"
                  image={
                    <Image
                      width="100px"
                      src={Nadine}
                      borderRadius={"999px"}
                      transparent
                    />
                  }
                />
              </Card>
            </Cell>
            <Cell span={12}>
              <FormField label="Enter a prompt">
                {isProcessing ? (
                  <Input
                    status="loading"
                    readOnly={true}
                    value={prompt}
                    size="large"
                    border="round"
                  />
                ) : (
                  <PulseAnimation
                    active={isFirstTime}
                    fluid={true}
                    color="B10"
                    borderRadius="18px"
                  >
                    <Input
                      border="round"
                      value={prompt}
                      maxLength={100}
                      size="large"
                      suffix={[
                        <Box
                          height={"100%"}
                          direction="horizontal"
                          verticalAlign="middle"
                        >
                          <Button
                            size="tiny"
                            onClick={() => getCompletion("completion")}
                            disabled={prompt.length === 0}
                          >
                            {completion.length === 0
                              ? "Generate Code"
                              : "Complete the code"}
                          </Button>
                        </Box>,
                      ]}
                      onChange={(e) => setPrompt(e.target.value)}
                      autoFocus={true}
                    />
                  </PulseAnimation>
                )}
              </FormField>
            </Cell>
            <Cell span={1}>
              <FormField
                label="Use pre-trained code"
                labelPlacement="right"
                infoContent={
                  "Enabling this helps you to search code examples from Velo documentation."
                }
              >
                <Box direction="horizontal" gap={"SP1"}>
                  <Badge size="tiny" skin="warning">
                    Coming soon
                  </Badge>
                  <ToggleSwitch
                    disabled
                    checked={usePretrainedModel}
                    onChange={() => setUsePretrainedModel(!usePretrainedModel)}
                    size="small"
                  />
                </Box>
              </FormField>
            </Cell>
            <Cell span={11}></Cell>
            <Cell span={isBeta ? 8 : 12}>
              <FormField label="Steps to code">
                <InputArea
                  id="completion"
                  ref={inputAreaRef}
                  minHeight="300px"
                  resizable
                  placeholder="Generated code will appear here"
                  value={completion}
                  onChange={(e: any) => setCompletion(e.target.value)}
                />
              </FormField>
            </Cell>
            {isBeta ? (
              <Cell span={4}>
                <Box direction="vertical" gap="SP4">
                  <Card>
                    <MarketingLayout
                      title="Insert"
                      description={
                        <Text size="small">
                          Place{" "}
                          <Text size="small" weight="bold">
                            [insert]
                          </Text>{" "}
                          to indicate where you want to insert the code.
                        </Text>
                      }
                      actions={
                        <Button
                          size="small"
                          onClick={() => {
                            // getCompletion("insert")
                          }}
                          // disable depends on text area have "[insert]" in it
                          disabled={true} //{completion.indexOf("[insert]") === -1 || isProcessing}
                        >
                          Coming soon
                        </Button>
                      }
                      size="tiny"
                    />
                  </Card>
                  <Card>
                    <MarketingLayout
                      title="Instruct"
                      description={
                        <Text size="small">
                          Tell the{" "}
                          <Text size="small" weight="bold">
                            instruction
                          </Text>{" "}
                          to generate better code
                          <Box gap={"SP2"} direction="vertical">
                            <Box></Box>
                            <Input
                              value={editInstructions}
                              size="small"
                              placeholder="Fix the bug: "
                              onChange={(e) => {
                                setEditInstructions(e.target.value);
                              }}
                            />
                          </Box>
                        </Text>
                      }
                      actions={
                        <Button
                          size="small"
                          disabled={
                            editInstructions.length === 0 ||
                            completion.length === 0 ||
                            isProcessing
                          }
                          onClick={() => {
                            getCompletion("edit");
                          }}
                        >
                          Run
                        </Button>
                      }
                      size="tiny"
                    />
                  </Card>
                </Box>
              </Cell>
            ) : null}
            {/* <Cell>
              <PageSection title="Suggested for you" showDivider />
            </Cell>
            <Cell span={6}>
              <Card>
                <MarketingLayout
                  title="Get expert tips from Velo forum"
                  description="Get answers to your questions from the community."
                  actions={<Button size="small" as="a" href="https://www.wix.com/velo/forum" target="_blank">Go to Forum</Button>}
                  size="tiny"
                  badge={<Badge size="small">Community</Badge>}
                  image={
                    <Box width="100%" align="right">
                      <Image
                        width="120px"
                        src="https://static.wixstatic.com/media/0784b1_f7af04acdd3d482dbc5ccf0491ae248d~mv2.png"
                        transparent
                      />
                    </Box>
                  }
                />
              </Card>
            </Cell> */}
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
